import React, { useEffect, useState, createContext } from "react";

const trackingIdTypes = {
	gclid: '',
	fbclid: ''
}
export const TrackingIDContext = createContext(trackingIdTypes);
 
export default function TrackingIds({
	children,
	...props
}) {
	const [gclid, setGclid] = useState("");
	const [fbclid, setFbclid] = useState("");

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);

		const gclid = urlParams.has("gclid")
			? `&gclid=${urlParams.get("gclid")}`
			: ``;
		setGclid(gclid);

		const fbclid = urlParams.has("fbclid")
			? `&fbclid=${urlParams.get("fbclid")}`
			: ``;
		setFbclid(fbclid);
	}, []);

	return (
		<TrackingIDContext.Provider value={{gclid, fbclid}}>
			{children}
		</TrackingIDContext.Provider>
	);
}
