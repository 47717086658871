const { createElement } = require("react");
const {
	default: GraphQlSourceProvider,
} = require("../components/GraphQlSourceProvider");

/* global GATSBY_PLUGIN_GRAPHQL_PREVIEW_ISOLATED_QUERIES */
const isolatedQueries = GATSBY_PLUGIN_GRAPHQL_PREVIEW_ISOLATED_QUERIES;

exports.default = ({ element }, options) => {
	const { fieldName, typeName, url, headers, credentials } = options;
	let previewApiUrl = process.env.GATSBY_PREVIEW_API_URL;
	return createElement(
		GraphQlSourceProvider,
		{
			sourceOptions: {
				fieldName,
				typeName,
				url: previewApiUrl ? previewApiUrl : url,
				headers,
				credentials,
				isolatedQueries,
			},
		},
		element
	);
};
