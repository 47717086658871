import React, { useEffect, useState } from "react";
import { parse } from "query-string";

export default function PreviewContainer({
	children,
	location,
	pageContext,
	...props
}) {
	const [previewContext, setPreviewContext] = useState();
	useEffect(() => {
		(async () => {
			const queryParams = parse(location.search);
			if (
				!queryParams ||
				(!queryParams["entryId"] &&
					!queryParams["x-craft-preview"] &&
					!queryParams["x-craft-live-preview"])
			)
				return;

			const {
				locatePageQueryByType,
				locatePageQuery,
				RuntimeDataProvider,
			} = await import("gatsby-plugin-graphql-runtime");

			const pageQuery =
				locatePageQueryByType(queryParams["entryType"]) ||
				locatePageQuery(pageContext);
			if (!pageQuery) return;
			const pageId =
				pageContext.id ||
				(!!queryParams["entryId"] && parseInt(queryParams["entryId"], 10)) ||
				null;

			if (!pageId) return;

			setPreviewContext({
				previewProps: {
					pageQuery,
					headers: { "x-craft-token": queryParams["x-craft-token"] },
					pageContext: {
						...pageContext,
						entryType: queryParams["entryType"],
						id: pageId,
					},
				},
				PreviewProvider: RuntimeDataProvider,
			});
		})();
	}, [location.search, location.pathname, pageContext]);

	if (!previewContext) return children;
	const { previewProps, PreviewProvider } = previewContext;

	return (
		<PreviewProvider {...previewProps} {...props}>
			{children}
		</PreviewProvider>
	);
}
