module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#352037","theme_color":"#352037","display":"minimal-ui","icon":"src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"620466dcd78e4debdfc2c565ad9aefed"},
    },{
      plugin: require('../plugins/gatsby-plugin-graphql-runtime/gatsby-browser.js'),
      options: {"plugins":[],"typeName":"CraftCMS","fieldName":"craft","url":"https://tlp-admin-live.ghost.st/api","headers":{"Authorization":"Bearer hJc2qNU0MBfTWR9L1DDF1-I7QhyfVIRe"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PS4TF52","includeInDevelopment":false,"routeChangeEventName":"pageview","defaultDataLayer":{"type":"function","value":"function () {\n\t\t\t\t\treturn {\n\t\t\t\t\t\toriginalLocation:\n\t\t\t\t\t\t\tdocument.location.protocol +\n\t\t\t\t\t\t\t\"//\" +\n\t\t\t\t\t\t\tdocument.location.hostname +\n\t\t\t\t\t\t\tdocument.location.pathname +\n\t\t\t\t\t\t\tdocument.location.search,\n\t\t\t\t\t};\n\t\t\t\t}"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
